import React from "react"
import SettingsLayout from "../components/settings-layout"

export default () => (
  <SettingsLayout>

      <div className="head-title">
        <h2>
          Submission successful
        </h2>
      </div>

        <div className="head-body">
          <p>
            Thank you for sharing your email.
        	</p>
        	<p>
						In the future, I'll send heads up on new features and releases.
          </p>
        </div>

    </SettingsLayout>
)